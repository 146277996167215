@import url(https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap);
.Toastify__toast {
  border-radius: 4px !important;
  cursor: default !important;
}

.Toastify__toast-container {
  width: -moz-max-content !important;
  width: max-content !important;
}

.Toastify__toast-body {
  padding: 10px;
}

.Toastify__toast-body a {
  font-weight: bold;
}

.Toastify__close-button {
  color: inherit !important;
  align-self: center !important;
  margin-right: 10px;
  cursor: pointer;
}



.Toastify__toast--info {
  background-color: #FFF3CD !important;
  border: 1px solid #FFEEBA !important;
  color: #856404;
}

.Toastify__toast--info a {
  color: #856404;
  cursor: pointer;
}



.Toastify__toast--success {
  background-color: #d3ead8 !important;
  border: 1px solid #C3E6CB !important;
  color: #2E5424;
}

.Toastify__toast--success a {
  color: #2B7EFA;
  cursor: pointer;
}


.poStatusToastrBody {
  background: var(--zircon) !important;
  padding: 0 !important;
  margin-right: 15px !important;
  margin-left: 16px !important;
}

#poStatusToast {
  padding: 0 !important;
  background: var(--zircon) !important;
  border: 1px solid var(--dodger_blue);
  border-radius: 6px !important;
  border-left: solid 6px var(--dodger_blue);
  border-color: var(--dodger_blue);
  width: 503px;
  height: 88px;
}

#poStatusCloseButton {
  margin: auto !important;
  margin-right: 15px !important;
}

@media only screen and (max-width: 600px) {
  .Toastify__toast-container {
    width: 100% !important;
    padding: 8px !important;
  }
}

/*
  Narrowed fa icon-font needed to replace icons of kendo-ui
  To include additional icons please refer to scripts/font-awesome
*/
@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(/static/media/fa-regular-400-set.93cd93e8.woff2) format("woff2");
}

@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(/static/media/fa-solid-900-set.1d957ec6.woff2) format("woff2");
}

* {
  scroll-behavior: inherit !important;
}

/* use "hide-scroll" class to hide scrollbars but keep functionality */
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar,
.hide-scroll div::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
  height: 6px;
}

.hide-scroll::-webkit-scrollbar-thumb,
.hide-scroll div::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll,
.hide-scroll div {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

:root {
  --navy: #011fbb;
  --almost_black: #080b11;
  --alabaster: #f9fbff;
  --medium-grey: #7d7e81;
  --water: #eef3ff;
  --soft-peach: #ebedf2;
  --zeron: #f5f8ff;
  --white: #ffffff;
  --font_gray: #767b87;
  --iron-gray: #d3d3e2;
  --light-gray: #ccd4d9;
  --dawn: #a2a2a2;
  --lavender-mist: #e2e8f0;
  --zircon: #f2f9ff;
  --dodger_blue: #4d88ff;
}

.x-hellosign-embedded {
  z-index: 9999;
}

.Toastify__toast,
.Toastify__toast-body,
.Toastify__toast-container,
.Toastify__close-button {
  font-family: "Sora";
}
.containing-box-scrollbar {
  -ms-overflow-style: scrollbar !important;
  scrollbar-width: auto !important;
}
.containing-box-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 9px !important;
  height: 9px !important;
}

.containing-box-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.containing-box-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: var(--light-gray);
  border-radius: 1ex;
}

.MuiFormGroup-root .MuiRadio-root {
  padding-left: 0;
}

.MuiFormGroup-row .MuiRadio-root {
  padding-left: 11px;
}

.SwitchCompany_customRows__38si- {
  overflow-y: scroll;
  height: 500px;
}

.SwitchCompany_customRows__38si-::-webkit-scrollbar {
  width: 10px;
}

.SwitchCompany_customRows__38si-::-webkit-scrollbar-track {
  background: #e8e9ed;
  border-radius: 12px;
}

.SwitchCompany_customRows__38si-::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}
/* For pop up window - schema, fieldConfig in document Type management grid  start here*/
.gridTextAreaForm .k-textarea>.k-input {
  resize: none;
  font-size: 14px;
  font-weight: 400;
}

.gridTextAreaForm>.k-textarea::-moz-selection, .k-textarea .k-input::-moz-selection {
  background-color: #011FBB;
}

.gridTextAreaForm>.k-textarea::selection,
.k-textarea .k-input::selection {
  background-color: #011FBB;
}

.gridTextAreaForm .k-form-field {
  margin-top: 0;
}

.gridTextAreaForm .MuiButton-root.Mui-disabled {
  color: white;
}

.gridTextAreaForm .MuiTypography-h6 {
  position: static;
  height: 18px;
  left: 20px;
  top: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.checkbox-cell {
  text-align: center !important;
}

/* For pop up window - schema, fieldConfig in document Type management grid ends here */
.Toastify-console-log-common {
  color: white !important;
  font-weight: 400;
}
.Toastify-console-log-common h4 {
  margin: 0 0 0.3em 0;
}

.Toastify-console-log-common p {
  margin: 0 0 0.3em 0;
  overflow-wrap: anywhere;
}

.Toastify-console-log-log {
  background: #0088ff !important;
}

.Toastify-console-log-info {
  background: #44ff00 !important;
}

.Toastify-console-log-warn {
  background: #ff5e00 !important;
}

.Toastify-console-log-error {
  background: #ff0044 !important;
}

