@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");

/*
  Narrowed fa icon-font needed to replace icons of kendo-ui
  To include additional icons please refer to scripts/font-awesome
*/
@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../../assets/fonts/fa-regular-400-set.woff2") format("woff2");
}

@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url("../../assets/fonts/fa-solid-900-set.woff2") format("woff2");
}

* {
  scroll-behavior: inherit !important;
}

/* use "hide-scroll" class to hide scrollbars but keep functionality */
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar,
.hide-scroll div::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
  height: 6px;
}

.hide-scroll::-webkit-scrollbar-thumb,
.hide-scroll div::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll,
.hide-scroll div {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

:root {
  --navy: #011fbb;
  --almost_black: #080b11;
  --alabaster: #f9fbff;
  --medium-grey: #7d7e81;
  --water: #eef3ff;
  --soft-peach: #ebedf2;
  --zeron: #f5f8ff;
  --white: #ffffff;
  --font_gray: #767b87;
  --iron-gray: #d3d3e2;
  --light-gray: #ccd4d9;
  --dawn: #a2a2a2;
  --lavender-mist: #e2e8f0;
  --zircon: #f2f9ff;
  --dodger_blue: #4d88ff;
}

.x-hellosign-embedded {
  z-index: 9999;
}

.Toastify__toast,
.Toastify__toast-body,
.Toastify__toast-container,
.Toastify__close-button {
  font-family: "Sora";
}
.containing-box-scrollbar {
  -ms-overflow-style: scrollbar !important;
  scrollbar-width: auto !important;
}
.containing-box-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 9px !important;
  height: 9px !important;
}

.containing-box-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.containing-box-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: var(--light-gray);
  border-radius: 1ex;
}

.MuiFormGroup-root .MuiRadio-root {
  padding-left: 0;
}

.MuiFormGroup-row .MuiRadio-root {
  padding-left: 11px;
}
