.Toastify__toast {
  border-radius: 4px !important;
  cursor: default !important;
}

.Toastify__toast-container {
  width: max-content !important;
}

.Toastify__toast-body {
  padding: 10px;
}

.Toastify__toast-body a {
  font-weight: bold;
}

.Toastify__close-button {
  color: inherit !important;
  align-self: center !important;
  margin-right: 10px;
  cursor: pointer;
}



.Toastify__toast--info {
  background-color: #FFF3CD !important;
  border: 1px solid #FFEEBA !important;
  color: #856404;
}

.Toastify__toast--info a {
  color: #856404;
  cursor: pointer;
}



.Toastify__toast--success {
  background-color: #d3ead8 !important;
  border: 1px solid #C3E6CB !important;
  color: #2E5424;
}

.Toastify__toast--success a {
  color: #2B7EFA;
  cursor: pointer;
}


.poStatusToastrBody {
  background: var(--zircon) !important;
  padding: 0 !important;
  margin-right: 15px !important;
  margin-left: 16px !important;
}

#poStatusToast {
  padding: 0 !important;
  background: var(--zircon) !important;
  border: 1px solid var(--dodger_blue);
  border-radius: 6px !important;
  border-left: solid 6px var(--dodger_blue);
  border-color: var(--dodger_blue);
  width: 503px;
  height: 88px;
}

#poStatusCloseButton {
  margin: auto !important;
  margin-right: 15px !important;
}

@media only screen and (max-width: 600px) {
  .Toastify__toast-container {
    width: 100% !important;
    padding: 8px !important;
  }
}
